<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-2" style="width: 100%">

          <!-- Left-aligned content -->
          <div class="d-flex align-center">
            <v-select
              v-model="type"
              :items="formattedTypes"
              label="Type"
              dense
              outlined
              clearable
              hide-details
              class="mr-2 custom-search-perm"
              @change="$emit('fetchGlobalSettings', { type: type })"
            ></v-select>
          </div>

          <!-- Right-aligned refresh button -->
          <div class="ml-auto">
            <v-btn
              text
              light
              color="primary"
              class="ml-2"
              @click="$emit('fetchGlobalSettings', true)"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>
          </div>

        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <router-link v-if="item.type.length < 86" class="text-decoration-none" :to="{ name: 'globalSettingsEdit', params: { id: item.id } }">
          <div><v-icon class="mr-2" color="primary">{{ iconHandler(item.type) }}</v-icon>{{ $lang.status[item.type] }}</div>
        </router-link>
      </template>
      
      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { definitions } from '@/utils/definitions'
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      type: '',
      headers: [
        { text: this.$lang.labels.type, value: 'type', sortable: false }
      ],
      roles: [],
      types: definitions.GlobalSetting.properties.type.enum
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings']),
    formattedTypes() {
      return this.types.map((x) => ({ text: this.$lang.status[x], value: x }))
    }
  },
  watch: {
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchGlobalSettings', { type: this.type })
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(1, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn', sortable: true })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    this.$emit('fetchGlobalSettings', { type: this.type })

    setTimeout(() => this.lock = false, 100)
  },
  methods: {
    iconHandler(type) {
      switch (type) {
      case 'PROCESSING':
        return 'mdi-flip-to-back'
      case 'FRONTEND':
        return 'mdi-flip-to-front'
      default:
        return 'mdi-cog-outline'
      }
    }
  }
}
</script>
